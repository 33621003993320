import {React, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem

} from 'reactstrap';



function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div>
        <Navbar color="light" light expand="md" fixed="top" style={{ fontWeight: "bold" }}>
          <NavbarBrand href="/"><strong>NETINVENTIONS<span style={{ color: "#106eea" }}>.</span></strong></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="justify-content-end"
              style={{ width: '100%' }}

              navbarlight="true"
            >
              <NavItem>
                <NavLink href="/components/">Services</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">About</NavLink>
              </NavItem>
              <UncontrolledDropdown inNavbar nav disabled>
                <DropdownToggle caret nav>
                  Customers
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="login">Login</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className="image-background-home">
          {/*<img src={bck} alt="img" />*/}
        </div>
      </div>
    </>
  );
}

export default App;
